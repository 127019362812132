/* You can add global styles to this file, and also import other style files */

// html, body { height: 100%; }

/* --global-FontFamily-- */
/* --global--regular--FontFamily: Avenir Next, Regular;
  --global--medium--FontFamily: Avenir Next, Medium;
  --global--bold--FontFamily: Avenir Next, bold;
  --global--semi-bold--FontFamily: Avenir Next, Semi-bold; */

@font-face {
  font-family: DNVDisplayMedium;
  src: url(./assets/fonts/DNVDisplay-Medium.ttf) format('truetype');
  font-style: normal;
}

@font-face {
  font-family: DNVDisplayRegular;
  src: url(./assets/fonts/DNVDisplay-Regular.ttf) format('opentype');
  font-style: normal;
}

@font-face {
  font-family: AvenirNext;
  src: url(./assets/fonts/AvenirNextLTPro-Regular.otf) format('opentype');
  font-style: normal;
}

@font-face {
  font-family: AvenirNextBold;
  src: url(./assets/fonts/AvenirNextLTPro-Bold.otf) format('opentype');
  font-style: bold;
}

#contactUsForm {
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent;
    padding: 0;
    width: 440px;

    @media only screen and (max-width: 767px) {
      width: 367px;
    }

    @media only screen and (min-width: 768px) and (max-width: 1350px) {
      width: 300px;
    }

    @media only screen and (min-width: 1900px) {
      width: 476px;
    }
  }

  .mat-mdc-form-field:not(.mat-form-field-disabled)
    .mat-mdc-floating-label.mdc-floating-label {
    color: #0f204b;
    font-family: 'AvenirNext' !important;
    font-weight: 600;
  }

  .mat-mdc-form-field-focus-overlay {
    background-color: transparent;
  }

  .mat-mdc-checkbox .mdc-form-field {
    padding: 0 !important;
    align-items: start;
  }

  .mdc-checkbox {
    transform: translate(0, -8px);
  }

  .mat-mdc-raised-button:not(:disabled) {
    border-radius: 6px;
    background: var(--P3-003591-Sea-Blue, #003591);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  // .mat-mdc-focus-indicator {
  //   display: inline-flex;
  //   padding: 13px 36px 12px 37px;
  //   justify-content: center;
  //   align-items: center;
  // }

  .mdc-button__label {
    font-family: 'AvenirNext' !important;
  }

  .mat-mdc-button-touch-target {
    height: 44px !important;
  }

  re-captcha.is-invalid > div {
    border: 1px solid #dc3545 !important;
    border-radius: 0.2rem;
  }
  .mat-mdc-form-field-required-marker {
    color: red;
  }
  .mat-mdc-form-field-error {
    transform: translate(-20px);
  }
}

.bold-text{
  font-family: AvenirNextBold !important;
}

.grecaptcha-badge {
  visibility: hidden;
}
